<template>
  <!-- 
  /*
当前文件为某个客户的特殊开发，不需要这不转移到app或小程序！！！！！！！！！！！！！！
*/
  -->
  <div class="jxDataSocre">
    <van-nav-bar
      :title="$t('jxDataSocreDetail.txt1')"
      :fixed="true"
      left-text
      :left-arrow="false"
    />
    <div class="fixedH" style="height: 1.22667rem"></div>
    <div class="container">
      <van-field
        :name="'等级建议'"
        :label="'等级建议'"
        required
        v-model="masterData.cus_khres"
        :placeholder="'请选择等级建议'"
        @click="comboboxNoispoptreefocus()"
      ></van-field>

      <van-collapse v-model="activeNames">
        <van-collapse-item
          v-for="(value, key) in resData"
          :key="key"
          :title="value.itemname"
          :name="'active' + (key + 1)"
        >
          <!-- <van-cell-group>
            <van-cell
              :title="'目标值：' + value.tgvaluenew"
              :value="'单位：' + value.tgvalue_dw"
            />
          </van-cell-group> -->
          <div class="score_shabox">
            <div class="thrid_per">目标值：{{ value.tgvaluenew }}</div>
            <div class="thrid_tg">
              单位：
              {{ value.tgvalue_dw }}
            </div>
          </div>
          <van-collapse
            class="childCollapse"
            v-model="value.activeNames2"
            @change="changeTwo(value, 'gz')"
          >
            <van-collapse-item :name="'f1'">
              <template #title>
                <div class="second_title">
                  <div class="second_name">指标说明与计算方法</div>
                </div>
              </template>
              <template #right-icon>
                <div class="right-icon">
                  {{
                    `${
                      value.showgz
                        ? $t('jxDataSocreDetail.txt6')
                        : $t('jxDataSocreDetail.txt7')
                    }`
                  }}
                  <van-icon :name="value.showgz ? 'arrow-up' : 'arrow-down'" />
                </div>
              </template>

              <div class="tips_container">
                <div class="khf_shabox">
                  <p>{{ value.itemdesc }}</p>
                  <div class="score_shabox">
                    <div v-if="$t('jxDataSocreDetail.txt2')" class="thrid_per">
                      {{ $t('jxDataSocreDetail.txt2') }}{{ value.itemper }}
                    </div>
                    <div v-if="$t('jxDataSocreDetail.txt3')" class="thrid_tg">
                      单位：%
                    </div>
                  </div>
                </div>
              </div>
            </van-collapse-item>
          </van-collapse>
          <div class="score_shabox">
            <div v-if="$t('jxDataSocreDetail.txt2')" class="thrid_per">
              指标实际值：{{ value.zbz }}
            </div>
            <div v-if="$t('jxDataSocreDetail.txt3')" class="thrid_tg">
              单位：
              {{ value.realscore_dw }}
            </div>
          </div>
          <van-collapse
            class="childCollapse"
            v-model="value.activeNames3"
            @change="changeTwo(value, 'zw')"
          >
            <van-collapse-item :name="'f2'">
              <template #title>
                <div class="second_title">
                  <div class="second_name">手工评分规则/标准</div>
                </div>
              </template>
              <template #right-icon>
                <div class="right-icon">
                  {{
                    `${
                      value.showzw
                        ? $t('jxDataSocreDetail.txt6')
                        : $t('jxDataSocreDetail.txt7')
                    }`
                  }}
                  <van-icon :name="value.showzw ? 'arrow-up' : 'arrow-down'" />
                </div>
              </template>
              <div class="tips_container">
                <div class="khf_shabox">
                  <p>{{ value.cus_handpftype }}</p>
                </div>
              </div>
              <div></div>
            </van-collapse-item>
          </van-collapse>
          <van-collapse
            class="childCollapse"
            v-model="value.activeNames4"
            @change="changeTwo(value, 'zj')"
          >
            <van-collapse-item :name="'f3'">
              <template #title>
                <div class="second_title">
                  <div class="second_name">工作总结(员工本人填写)</div>
                </div>
              </template>
              <template #right-icon>
                <div class="right-icon">
                  {{
                    `${
                      value.showzjdesp
                        ? $t('jxDataSocreDetail.txt6')
                        : $t('jxDataSocreDetail.txt7')
                    }`
                  }}
                  <van-icon
                    :name="value.showzjdesp ? 'arrow-up' : 'arrow-down'"
                  />
                </div>
              </template>
              <div class="tips_container">
                <div class="khf_shabox">
                  <p>{{ value.cus_workzjremark }}</p>
                </div>
              </div>
              <div></div>
            </van-collapse-item>
          </van-collapse>

          <!-- 评价说明 -->
          <van-collapse class="thrid_coll" v-model="value.activeNames5">
            <van-collapse-item :name="'f4'" title="评价说明">
              <div class="thrid_desc">
                <van-field
                  class="brief"
                  v-model="value.remark"
                  rows="5"
                  autosize
                  label
                  :readonly="!(jj || zz)"
                  type="textarea"
                  show-word-limit
                  style="margin-bottom: 15px"
                />
              </div>
            </van-collapse-item>
          </van-collapse>
          <!-- 直接/间接 -->
          <div class="select_container">
            <div class="select_input">
              <h3>直接上级评分</h3>

              <div>
                <input :disabled="!zz" type="text" v-model="value.zjsj_score" />
              </div>
            </div>
            <div class="select_input">
              <h3>间接上级评分</h3>
              <div>
                <input :disabled="!jj" type="text" v-model="value.jjsj_score" />
              </div>
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
    <div class="footer-statistics" v-if="jj || zz">
      <div class="footer-button">
        <van-button
          @click="submitDataEvent"
          type="primary"
          block
          color="#2B8DF0"
          >{{ $t('jxDataSocreDetail.txt17') }}</van-button
        >
      </div>
    </div>
    <van-popup
      v-model="showPicker"
      :close-on-click-overlay="false"
      :close-on-popstate="false"
      round
      position="bottom"
    >
      <van-picker
        :title="'等级建议'"
        show-toolbar
        :columns="choices"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>
<script>
import { Toast, Dialog } from 'vant'
import { gethpKpi, savehpKpi, EditWorkFlow, GetNodeidPower } from '@api/hp.js'
import { createGguid } from '@/utils'
export default {
  name: 'jxDataSocre',
  data() {
    return {
      username: '',
      empcode: '',
      empid: 0,
      autoid: this.$route.query.autoid,
      checkuserid: this.$route.query.checkuserid,
      mautoid: this.$route.query.mautoid,
      recid: this.$route.query.recid,
      formautoid: 24,
      activeNames: ['active1'],
      resData: [],
      masterData: {},
      jj: false,
      zz: false,
      showPicker: false,
      choices: []
    }
  },
  watch: {
    resData: {
      deep: true,
      handler(n) {
        let a = 0
        for (let i = 0; i < this.resData.length; i++) {
          if (this.resData[i].data) {
            for (let k = 0; k < this.resData[i].data.length; k++) {
              let data = this.resData[i].data[k]
              if (
                data.itemscore > data.tgvalue ||
                data.zjsj_score > data.tgvalue ||
                data.jjsj_score > data.tgvalue
              ) {
                Toast(this.$t('jxDataSocreDetail.txt18'))
                return false
              }
              if (data.totalscore || data.totalscore == 0) {
                let b = parseFloat(data.totalscore)
                console.log(b)
                a = a + b
              }
            }
          }
        }
        console.log(a)
        this.anum = a
        // 给指标附件改参数
        n.forEach(item => {
          if (item.data) {
            if (item.data.length > 0) {
              item.data.forEach(it => {
                if (it.zpFileList && it.zpFileList.length > 0) {
                  it.zpFileList.forEach(el => {
                    const guid = createGguid()
                    el.fileext = el.file.type.split('/')[1]
                    el.filename = '1_' + guid
                    el.filebase64str = el.content.split('base64,')[1]
                  })
                }
                if (it.zjFileList && it.zjFileList.length > 0) {
                  it.zjFileList.forEach(el => {
                    const guid = createGguid()
                    el.fileext = el.file.type.split('/')[1]
                    el.filename = '2_' + guid
                    el.filebase64str = el.content.split('base64,')[1]
                  })
                }
                if (it.jjFileList && it.jjFileList.length > 0) {
                  it.jjFileList.forEach(el => {
                    const guid = createGguid()
                    el.fileext = el.file.type.split('/')[1]
                    el.filename = '3_' + guid
                    el.filebase64str = el.content.split('base64,')[1]
                  })
                }
                if (it.tjFileList && it.tjFileList.length > 0) {
                  it.tjFileList.forEach(el => {
                    const guid = createGguid()
                    el.fileext = el.file.type.split('/')[1]
                    el.filename = '4_' + guid
                    el.filebase64str = el.content.split('base64,')[1]
                  })
                }
              })
            }
          }
        })
      }
    },
    zpFileList: {
      handler(n) {
        n.forEach(el => {
          const guid = createGguid()
          el.fileext = el.file.type.split('/')[1]
          el.filename = '1_' + guid
          el.filebase64str = el.content.split('base64,')[1]
        })
        // console.log(n, 'zpFileList');
      }
    },
    zjFileList: {
      handler(n) {
        n.forEach(el => {
          const guid = createGguid()
          el.fileext = el.file.type.split('/')[1]
          el.filename = '2_' + guid
          el.filebase64str = el.content.split('base64,')[1]
        })
        // console.log(n, 'zjFileList');
      }
    },
    jjFileList: {
      handler(n) {
        n.forEach(el => {
          const guid = createGguid()
          el.fileext = el.file.type.split('/')[1]
          el.filename = '3_' + guid
          el.filebase64str = el.content.split('base64,')[1]
        })
        // console.log(n, 'jjFileList');
      }
    }
  },
  created() {
    this.gethpKpi()
  },
  methods: {
    gethpKpi() {
      gethpKpi({
        checkuserid: this.checkuserid,
        mautoid: this.mautoid,
        autoid: this.autoid,
        recid: this.recid
      }).then(res => {
        if (res.list1[0].isbad) {
          Toast('当前记录已撤销')
          return false
        }
        this.empid = res.list1[0].empid
        this.empcode = res.list1[0].empcode
        this.username = res.list1[0].username
        this.formautoid = res.list1[0].formautoid
        res.list3.forEach(e => {
          e.activeNames2 = ['f1']
          e.activeNames3 = ['f2']
          e.activeNames4 = ['f3']
          e.activeNames5 = ['f4']
          e.showgz = true
          e.showzw = true
          e.showzjdesp = true
          e.showjjdesp = true
        })
        this.resData = res.list3
        this.masterData = res.list4[0]
        if (res.list2[0].ischeck) {
          Toast('当前节点已审核')
          return false
        }
        this.GetNodeidPower()
      })
    },
    GetNodeidPower() {
      GetNodeidPower({
        formautoid: this.formautoid,
        mautoid: this.recid,
        empcode: this.empcode
      }).then(res => {
        res.detail_field_pri.forEach(e => {
          if (e.fieldname === 'zjsj_score') this.zz = e.iswrite
          if (e.fieldname === 'jjsj_score') this.jj = e.iswrite
        })
      })
    },
    // 第二层Collapse change
    changeTwo(item, type) {
      if (item) {
        if (type == 'gz') {
          item.showgz = !item.showgz
        } else if (type == 'zw') {
          item.showzw = !item.showzw
        } else if (type == 'zj') {
          item.showzjdesp = !item.showzjdesp
        } else if (type == 'jj') {
          item.showjjdesp = !item.showjjdesp
        }
      }
      // 点击第二层Collapse时刷新
      this.$forceUpdate()
    },
    submitDataEvent() {
      let arr = [],
        cansave = true,
        cansave2 = true
      for (let i = 0; i < this.resData.length; i++) {
        let data = this.resData[i]
        // 加分项减分项不需要必填
        if (data.itemname != '减分项' && data.itemname != '加分项') {
          if (this.zz) {
            if (!data.zjsj_score) cansave = false
            if (data.zjsj_score > 120) cansave2 = false
          } else {
            if (!data.jjsj_score) cansave = false
            if (data.jjsj_score > 120) cansave2 = false
          }
        }

        let obj = {
          autoid: data.autoid,
          zjsj_score: String(data.zjsj_score) || 'null',
          jjsj_score: String(data.jjsj_score) || 'null',
          remark: String(data.remark) || ''
        }
        arr.push(obj)
      }
      if (!cansave) {
        Toast((this.zz ? '直接' : '间接') + '上级评分未填写完')
        return false
      }
      if (!cansave2) {
        Toast((this.zz ? '直接' : '间接') + '上级评分不能超过120')
        return false
      }
      savehpKpi({
        data: arr,
        autoid: this.masterData.autoid,
        cus_khres: this.masterData.cus_khres
      }).then(res => {
        if (res.iserror != '0') {
          Toast(res.errormsg)
        } else {
          EditWorkFlow({
            entname: 111,
            mautoid: this.recid,
            formautoid: this.formautoid,
            username: this.username,
            remark: '',
            isadd: 2,
            files: '',
            num: 0,
            type: 1
          }).then(res => {
            if (res.msg) {
              Toast(res.msg)
            } else {
              Toast(this.$t('module.SaveSuc'))
            }
            this.gethpKpi()
          })
        }
      })
    },
    // 获取下拉选项（combobox，lookupcombobox）
    comboboxNoispoptreefocus() {
      let tempdata = ['S', 'A', 'B+', 'B', 'C', 'D']
      this.choices = tempdata
      this.showPicker = true
    },
    onConfirm(value, index) {
      this.masterData.cus_khres = value
      this.showPicker = false
    },
    onCancel() {
      this.showPicker = false
    }
  }
}
</script>
<style lang="less" scoped>
.jxDataSocre {
  width: 100%;
  .van-nav-bar {
    background: #2b8df0;
    ::v-deep .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    ::v-deep .van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .container {
    width: 100%;
    padding: 30px;
    .van-collapse-item--border::after {
      border: 0 !important;
    }
    /deep/.van-collapse-item {
      .van-collapse-item__wrapper {
        background: #fff !important;
      }
      [class*='van-hairline']::after {
        border: 0 !important;
      }

      margin-bottom: 30px;
      .van-cell::after {
        border-bottom: 0 !important;
      }
      .van-collapse-item__title {
        background: #2b8df0;
        padding: 10px 15px;
        text-align: center;
        border-radius: 12px 12px 0 0;
        .van-cell__title span {
          color: #fff;
          font-size: 32px;
          font-weight: bold;
        }
        .van-cell__right-icon {
          color: #fff;
          font-size: 32px;
        }
      }
      .van-collapse-item__content {
        padding: 0 !important;
      }
      .van-collapse-item--border::after {
        left: 0 !important;
        right: 0 !important;
      }
      .childCollapse {
        .van-collapse-item__title {
          background: #fff !important;
          padding-bottom: 5px;
          text-align: left;
          padding: 20px 30px;
          border-radius: unset;
          .van-cell__title {
            color: #333 !important;
            font-size: 32px;
            font-weight: bold;
            .second_title {
              display: flex;
              justify-content: space-between;
              .second_name {
                width: 76%;
                text-align: left;
                font-weight: bold;
                .ic {
                  padding: 5px;
                  font-size: 22px;
                  border-radius: 5px;
                }
                .dl {
                  background: #dffadc;
                  color: #087908;
                }
                .dx {
                  background: #fde5d3;
                  color: #e85200;
                }
              }
              .second_score {
                color: #2b8df0;
                margin-right: 10px;
              }
            }
          }
          .van-cell__right-icon {
            color: #333 !important;
            font-size: 32px;
          }
        }
      }
      .thrid_coll {
        .van-collapse-item {
          padding: 20px 0;
          margin-bottom: 0;
          border-radius: unset;
          border-bottom: unset;
          .van-cell::after {
            border-bottom: unset;
          }
          .van-collapse-item__wrapper {
            padding: 0;
            .van-collapse-item__content {
              padding: 0;
              .thrid_desc {
                // box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
                // border-radius: 12px;
                margin: 16px 4px 4px 4px;
                .desp {
                  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
                  border-radius: 12px;
                  padding: 20px;
                  color: #666666;
                  font-size: 24px;
                  min-height: 5em;
                  .Sta_poj {
                    padding-bottom: 12px;
                    color: #333333;
                    display: flex;
                    justify-content: space-between;
                    border-bottom: 0.02667rem solid #ebedf0;
                  }
                }
                .brief {
                  border: 1px solid #ccc;
                  border-radius: 12px;
                }
              }
              // .thrid_Sta {
              //   box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
              //   border-radius: 12px;
              //   margin: 16px 4px 4px 4px;
              //   min-height: 5em;

              // }
            }
          }
        }
      }
    }
    /deep/ .zp_coll_item {
      .van-cell {
        background: #ff8000;
      }
    }
    /deep/ .zj_coll_item {
      .van-cell {
        background: #04e1a4;
      }
    }
    /deep/ .jj_coll_item {
      .van-cell {
        background: #b463f2;
      }
    }
    .childCollapse {
      .van-collapse-item--border::after {
        border-top: 0.02667rem solid #ebedf0 !important;
      }
    }
    .tips_container {
      padding: 10px 18px;
      width: 100%;

      .van-field {
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
        border: 0;
      }
    }
    .select_container {
      width: 100%;
      display: flex;
      justify-content: space-around;
      margin-top: 0.4rem;
      .select_input {
        width: 25%;
        div {
          text-align: center;
        }
        h3 {
          margin: 0;
          font-size: 28px;
          color: #666;
          text-align: center;
        }
        input {
          width: 80%;
          height: 66px;
          background: #ffffff;
          box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.18);
          border-radius: 10px;
          border: 0;
          margin-bottom: 6px;
          margin-top: 12px;
          text-align: center;
          color: #2b8df0;
          font-size: 36px;
        }
      }
    }
    .score_shabox {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 12px;
      font-size: 28;
      .thrid_per {
        color: #333333;
      }
      .thrid_tg {
        color: #333333;
      }
    }
    .khf_shabox {
      margin-bottom: 20px;
      border-radius: 12px;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
      width: 100%;
      padding: 35px 30px;
      p {
        color: #666;
        font-size: 24px;
      }

      * {
        margin: 0;
        padding: 0;
      }
    }
  }
  .footer-statistics {
    width: 100%;
    background: #fff;
    padding: 34px 30px 28px;
  }
  .footer-list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 60px;
    align-items: center;
    .kh_Word {
      font-size: 32px;
    }
    .kh_Socre {
      color: #2b8df0;
      font-size: 40px;
    }
  }
  .footer-button {
    width: 100%;
    padding: 25px 50px 0;
    margin-top: 20px;
  }
}

input[disabled],
input:disabled {
  background: #ddd !important;
}
</style>
